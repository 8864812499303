.builderCanvas {
  height: 100% !important;
  min-height: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
}

.mintingFriendsieOverlay {
  background-color: rgba(20, 20, 20, 0.5);
}

.mintingFriendsieModal {
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
}

.frengenLogoPainInMyAss {
  height: auto;
  max-height: 100%;
}

.frengenLogoContainer {
  position: fixed;
  height: 15%;
  margin-top: 20px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.mintWidgetContainer {
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  max-width: 12rem;
}

.tweetButton {
  background-color: #00acee;
}

.visitOnDesktopToMint {
  font-size: 0.6rem;
  color: #0dbd00;
  background-color: #f2ff00;
  padding: 2px 0px 2px 0px;
}

.mintButton {
  cursor: pointer;
  position: relative;
  padding: 0.5rem 1.5rem;
  border-radius: 3.75rem;
  /* line-height: 1rem; */
  letter-spacing: 1px;
  font-weight: 600;
  color: #0dbd00;
  border: none;
  text-align: center;
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  font-family: 'Fredoka One';
}

.mintButton::before {
  content: '';
  display: block;
  height: 0.125rem;
  position: absolute;
  top: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 3rem);
  background: #fff;
  border-radius: 100%;
  opacity: 0.7;
  background-image: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, #ffffff 20%, #ffffff 80%, rgba(255, 255, 255, 0) 100%);
}

.dotPulse {
  position: relative;
  left: -9999px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #0dbd00;
  color: #0dbd00;
  box-shadow: 9999px 0 0 -5px #0dbd00;
  animation: dotPulseAnim 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dotPulse::before,
.dotPulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #0dbd00;
  color: #0dbd00;
}

.dotPulse::before {
  box-shadow: 9984px 0 0 -5px #0dbd00;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dotPulse::after {
  box-shadow: 10014px 0 0 -5px #0dbd00;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #0dbd00;
  }
  30% {
    box-shadow: 9984px 0 0 2px #0dbd00;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #0dbd00;
  }
}

@keyframes dotPulseAnim {
  0% {
    box-shadow: 9999px 0 0 -5px #0dbd00;
  }
  30% {
    box-shadow: 9999px 0 0 2px #0dbd00;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #0dbd00;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #0dbd00;
  }
  30% {
    box-shadow: 10014px 0 0 2px #0dbd00;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #0dbd00;
  }
}
